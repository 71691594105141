import React from 'react'
import { appSlice, store } from '../store.js'

export default function NewGameButton() {
    function resetGame() {
        let randomNb = Math.floor(Math.random()*6) + 1
        store.dispatch(appSlice.actions.finding(randomNb))
        store.dispatch(appSlice.actions.rolling(''))
    }

    return (
        <button onClick={resetGame}>Nouvelle Partie</button>
    )
}
