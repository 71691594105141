import Dice from "./Dice";
import GoalDisplay from "./GoalDisplay";
import NewGameButton from "./NewGameButton";
import ResultDisplay from "./ResultDisplay";
import RollButton from "./RollButton";

function App() {
  return (
    <main>
      <GoalDisplay />
      <Dice />
      <ResultDisplay />
      <RollButton />
      <NewGameButton />
    </main>
  );
}

export default App;
