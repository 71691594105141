import React from 'react'
import { useSelector } from 'react-redux'
import Dice0 from "../assets/Dice.png"
import Dice1 from "../assets/Dice-1.png"
import Dice2 from "../assets/Dice-2.png"
import Dice3 from "../assets/Dice-3.png"
import Dice4 from "../assets/Dice-4.png"
import Dice5 from "../assets/Dice-5.png"
import Dice6 from "../assets/Dice-6.png"
import GIF from "../assets/Dice-Launch.gif"

export default function Dice() {
    const { rollNumber, isRolling } = useSelector((state) => state.app)

    let dicePic;
    let defaut = false;

    switch (rollNumber) {
        case 1:
            dicePic = Dice1;
            defaut = false;
            break;
        case 2:
            dicePic = Dice2;
            defaut = false;
            break;
        case 3:
            dicePic = Dice3;
            defaut = false;
            break;
        case 4:
            dicePic = Dice4;
            defaut = false;
            break;
        case 5:
            dicePic = Dice5;
            defaut = false;
            break;
        case 6:
            dicePic = Dice6;
            defaut = false;
            break;
        default :
            dicePic = Dice0;
            defaut = true
            break;
    }

    return (
        <>
            {isRolling ? (<img src={GIF} className='no-bg' alt="GIF Lancer de dés" />) : defaut ? (<img src={dicePic} className='no-bg' alt={rollNumber} />) : (<img src={dicePic} alt={rollNumber} />)}
        </>
    )
}
