import React, { useState } from 'react';
import { appSlice, store } from '../store.js'
import DiceSound from "../assets/Dice-Roll.mp3"

export default function RollButton() {
    const [playSound, setPlaySound] = useState(false);

    function newRoll() {
        setPlaySound(true)
        store.dispatch(appSlice.actions.isrolling())
        let randomNb = Math.floor(Math.random()*6) + 1
        store.dispatch(appSlice.actions.rolling(randomNb))
        setTimeout(() => {
            store.dispatch(appSlice.actions.isrolling());
            setPlaySound(false);
        }, 800);
    }

    return (
        <>
            {playSound && <audio src={DiceSound} autoPlay />}
            <button className="roll-btn" onClick={newRoll}>Lancer le dé</button>
        </>
    )
}
