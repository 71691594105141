import React from 'react'
import { useSelector } from 'react-redux'

export default function ResultDisplay() {
    const { numberToFind, rollNumber, isRolling } = useSelector((state) => state.app)

    if (rollNumber !== "" && isRolling === false) {
        return (
            <>
                {rollNumber === numberToFind ? (<p className='won'>Tu as gagné ! Félicitations !</p>) : (<p className='lost'>Perdu ! Réessaye.</p>)}
            </>
        )
    } else if (isRolling === true) {
        return (
            <p>Lancement du dé...</p> 
        )
    } else {
        return ( 
            <p>Lance le dé !</p> 
        )
    }
    
}
