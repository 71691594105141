import React, { useEffect } from 'react'
import { appSlice, store } from '../store.js'
import { useSelector } from 'react-redux'

export default function GoalDisplay() {
    const { numberToFind } = useSelector((state) => state.app)    

    useEffect(() => {
        let randomNb = Math.floor(Math.random()*6) + 1
        store.dispatch(appSlice.actions.finding(randomNb))
    }, [])

    return (
        <h1>Vous devez obtenir <u>{numberToFind}</u> pour gagner.</h1>
    )
}
