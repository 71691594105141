import { combineReducers, createSlice, createStore } from '@reduxjs/toolkit'

export const appSlice = createSlice({
    name: 'app',
    initialState: {
        hasWon: false,
        numberToFind: '',
        rollNumber: '',
        isRolling: false
    },
    reducers: {
        winning: (state) => {
            state.hasWon = !state.hasWon
        },
        finding: (state, action) => {
            state.numberToFind = action.payload
        },
        rolling: (state, action) => {
            state.rollNumber = action.payload
        },
        isrolling: (state) => {
            state.isRolling = !state.isRolling
        }
    },
})

const reducer = combineReducers({
    app: appSlice.reducer
})

export const store = createStore(reducer)